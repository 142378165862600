<template>
  <div class="body searching__body searching__body--3">
    <div class="logo-icon">
      <img src="img/pass-icon-3.png" alt="icon" />
    </div>
    <h2 class="body__title title">
      Ой... <br />Возникла проблема <br />
      с оплатой.
    </h2>
    <span class="separator" aria-hidden="true"></span>
    <h3 class="body__subtitle subtitle">Что делать?</h3>
    <div class="label-block label-block--list">
      <div class="label-block__list label-block__text">
        <ul>
          <li>
            — Попробуйте
            <a :href="initialUrl">повторить оплату</a>
          </li>
          <li>— <a :href="appLink">Скачать приложение ParkPass</a></li>
          <li>— Оплатить в паркомате 😢</li>
        </ul>
      </div>
    </div>
    <p class="searching__bottom-desc  small-text">
      Мы обязательно разберёмся, <br />
      что случилсось. А все виновные будут наказаны!
      <span class="small-text__icon">😡</span>
    </p>
  </div>
</template>

<script>
import Helpers from "@/mixins/Helpers";

export default {
  name: "PaymentError",
  mixins: [Helpers],
  data() {
    return {
      initialUrl: this.$localStorage.get("initialUrl"),
      test: this.$localStorage.get("test")
    };
  },
  created() {
    document.querySelector("html").scrollTop = 0;
  },
  computed: {
    appLink() {
      if (this.iOS || this.isSafari) {
        return "https://itunes.apple.com/ru/app/parkpass/id1397876828?mt=8";
      }
      return "https://play.google.com/store/apps/details?id=com.parkpass.app";
    }
  },
  beforeDestroy() {
    this.resetLocalStorage();
  }
};
</script>

<style scoped></style>
