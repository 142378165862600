<template>
  <div>
    <div class="body payment__body payment__body--success">
      <div class="payment__logo-icon logo-icon">
        <img src="img/pass-icon-2.png" alt="icon" />
      </div>
      <h2 class="body__title title title--bold">Парковка оплачена!</h2>
      <p class="body__desc small-text">
        {{ localStorage.parking_name }}: {{ localStorage.debt }}р. за
        {{ getSessionTime(localStorage.duration) }}
      </p>

      <span class="separator" aria-hidden="true"></span>

      <div class="load-app__block-wrapper">
        <h3 class="body__subtitle subtitle">
          Поздравляем! <br />
          Теперь вы пользователь Parkpass:
        </h3>
        <div class="label-block label-block--load-app">
          <ul class="label-block__load-app">
            <li>
              <svg width="16" height="16">
                <use xlink:href="#check-icon"></use>
              </svg>
              Бесконтактный въезд и выезд
            </li>
            <li>
              <svg width="16" height="16">
                <use xlink:href="#check-icon"></use>
              </svg>
              Оплата парковки после выезда
            </li>
            <li>
              <svg width="16" height="16">
                <use xlink:href="#check-icon"></use>
              </svg>
              Абонементы на парковку
            </li>
          </ul>
          <a
            v-if="iOS || isSafari"
            href="https://itunes.apple.com/ru/app/parkpass/id1397876828?mt=8"
            class="load-app__link"
            aria-label="Скачать приложение в app store"
          >
            <img src="img/app-apple.png" width="138" alt="apple icon" />
          </a>
          <a
            v-else
            href="https://play.google.com/store/apps/details?id=com.parkpass.app"
            class="load-app__link"
            aria-label="Скачать приложение в google play"
          >
            <img src="img/app-google.png" width="164" alt="google play icon" />
          </a>
        </div>
      </div>

      <div class="body__block-wrapper">
        <h3 class="body__subtitle subtitle">Бесплатный выезд до</h3>
        <div class="label-block label-block--big-text">
          <p class="label-block__big-text">{{ freeLeaveTime }}</p>
        </div>
      </div>
    </div>
    <svg aria-hidden="true" style="display: none;">
      <symbol
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id="check-icon"
      >
        <path
          d="M15 7.36V8.004C14.9991 9.5135 14.5103 10.9823 13.6065 12.1913C12.7027 13.4003 11.4323 14.2847 9.98475 14.7127C8.5372 15.1407 6.99008 15.0893 5.57413 14.5662C4.15818 14.0431 2.94926 13.0763 2.12767 11.8099C1.30609 10.5436 0.915854 9.04565 1.01517 7.53942C1.11449 6.0332 1.69804 4.59943 2.6788 3.45196C3.65955 2.30448 4.98495 1.50477 6.45733 1.17211C7.92971 0.839444 9.47018 0.991643 10.849 1.60601"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.0001 2.40401L8.00015 9.41101L5.90015 7.31101"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </symbol>
    </svg>
  </div>
</template>

<script>
import moment from "moment";
import Api from "@/api";
import { showAlert } from "@/utils";
import Helpers from "@/mixins/Helpers";
export default {
  name: "PaymentSuccess",
  mixins: [Helpers],
  props: ["settings"],
  data() {
    return {
      leave_at: "",
      localStorage: {
        parking_name: this.$localStorage.get("parking_name"),
        duration: this.$localStorage.get("duration"),
        debt: this.$localStorage.get("debt"),
        sessionId: this.$localStorage.get("sessionId")
      }
    };
  },
  created() {
    document.querySelector("html").scrollTop = 0;
    const referrer = this.$localStorage.get("qrSiteReferrer");
    if (referrer) {
      // this.settings.qrSite = true
      document.querySelector("body").classList.add("qr-site-theme");
    }
    Api.getPaymentStatus({
      card_session: localStorage.sessionId
    }).then(res => {
      if (res.data.error) {
        showAlert("error", "", res.data.error);
        // this.$router.push({ name: "ErrorPage" });
      } else if (res.data.paid) {
        this.leave_at = res.data.leave_at;
      }
      if (referrer) {
        this.$localStorage.remove("qrSiteReferrer");
        window.location.href = referrer + "qr";
      }
    });
    // .catch(err => {
    //   this.$router.push({ name: "ErrorPage" });
    //   showAlert("error", "", err.response.data.message);
    // });
  },
  computed: {
    freeLeaveTime() {
      if (this.leave_at) {
        return moment.unix(this.leave_at).format("HH:mm");
      }
      return "---";
    }
  },
  methods: {},
  beforeDestroy() {
    this.resetLocalStorage();
  }
};
</script>

<style scoped></style>
