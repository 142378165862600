<template>
  <div class="body searching__body searching__body--3">
    <div class="logo-icon">
      <img src="img/pass-icon-3.png" alt="icon" />
    </div>
    <div class="body__title-wrap">
      <h2 class="body__title title">
        Ой... <br />Очень жаль, что мы нашли <br />не вашу сессию.
      </h2>
    </div>
    <span class="separator" aria-hidden="true"></span>
    <div class="animation-middle">
      <h3 class="body__subtitle subtitle">Что делать?</h3>
      <div class="label-block">
        <p class="label-block__text">
          К сожалению, придётся платить <br />в терминале оплаты парковки.
        </p>
      </div>
      <p class="searching__bottom-desc  small-text">
        Мы обязательно разберёмся, <br />
        что случилсось. А все виновные будут наказаны!
        <span class="small-text__icon">😡</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchingError",
  props: ["queryParamsError"],
  created() {
    document.querySelector("html").scrollTop = 0;
  }
};
</script>

<style scoped></style>
