<template>
  <div id="app">
    <router-view />
    <notifications group="common" position="top right" />
  </div>
</template>

<script>
import TinkoffPayWidget from "@/TinkoffPayWidget";

export default {
  created() {
    if (
      window.location.href.includes("success") ||
      window.location.href.includes("Success")
    ) {
      this.$router.push("/success");
    }
    // console.error("Тинькофф, гори в аду со своим эквайрингом!!!💔🔥🔥");
    document.querySelector("body").classList.add("body--loaded");
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    TinkoffPayWidget
  }
};
</script>
<style src="./assets/css/scss/main.scss" lang="scss"></style>
