import axios from "axios";

const PARKPASS_DOMAIN =
  process.env.NODE_ENV === "production"
    ? // pass
      "https://sandbox.parkpass.ru"
    : // "https://sandbox.parkpass.ru"
      "http://localhost:8080/park-pass-proxy";

// const RPS_DOMAIN =
//   process.env.NODE_ENV === "production"
//     ? "https://office.r-p-s.ru"
//     : "http://localhost:8080/rps-proxy";

export default {
  getSession(data) {
    return axios.post(
      `${PARKPASS_DOMAIN}/api/v1/parking/rps/cards/debt/`,
      data
    );
  },
  getSessionDetail(host, data) {
    const instance = axios.create();
    instance.defaults.timeout = 2500;
    return instance.post(`${host}api2/remote/Session/sessionInfo`, data);
  },
  initPayment(data) {
    return axios.post(
      `${PARKPASS_DOMAIN}/api/v1/parking/rps/cards/guest/payment/init/`,
      data
    );
  },
  getPaymentStatus(data) {
    return axios.post(
      `${PARKPASS_DOMAIN}/api/v1/parking/rps/cards/payment/status/`,
      data
    );
  },
  getAllParking(data) {
    return axios.get(`${PARKPASS_DOMAIN}/api/v1/parking/all/`, data);
  }
};
