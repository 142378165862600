import Vue from "vue";

export const showAlert = (type, title, text) => {
  Vue.prototype.$notify({
    group: "common",
    title,
    text,
    type
  });
};

export const emailIsValid = email => {
  /*eslint-disable */
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  /*eslint-enable */

  return re.test(String(email).toLowerCase());
};
