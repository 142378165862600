<template>
  <div class="body">
    <transition name="page-animation">
      <div class="logo-icon">
        <img src="img/pass-icon-1.png" alt="icon" />
      </div>
    </transition>
    <div class="animation-top">
      <h2 class="body__title title title--bold">
        {{ selectedParking ? selectedParking.name : "ParkPass" }}
      </h2>
      <p class="body__desc small-text">
        {{
          selectedParking
            ? selectedParking.address
            : "Простой способ оплаты парковки"
        }}
      </p>
    </div>
    <div class="control-wrap" v-if="page === SELECT_PARKING_PAGE">
      <div class="body__title-wrap">
        <h2 class="body__subtitle subtitle">Ваша парковка</h2>
      </div>
      <div class="select">
        <select v-model="selectedParking">
          <option :value="null" selected disabled>Выбрать парковку</option>
          <option :value="item" v-for="item in parkings" :key="item.id">{{
            item.name
          }}</option>
        </select>
        <svg
          class="select__arrow"
          width="16"
          height="16"
          viewBox="0 0 16 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.73205 12C8.96225 13.3333 7.03775 13.3333 6.26795 12L1.0718 3C0.301997 1.66667 1.26425 -1.46309e-06 2.80385 -1.32849e-06L13.1962 -4.19966e-07C14.7358 -2.8537e-07 15.698 1.66667 14.9282 3L9.73205 12Z"
            fill="#000000"
          />
        </svg>
      </div>
      <button
        class="btn"
        :class="{ 'btn--disabled': !selectedParking }"
        @click="selectParking"
      >
        Далее
      </button>
    </div>
    <div class="control-wrap" v-else>
      <div class="body__title-wrap">
        <h2 class="body__subtitle subtitle">Номер карты / билета / жетона</h2>
      </div>
      <input
        type="number"
        v-model="cardNumber"
        class="input"
        autofocus
        ref="input"
      />
      <button
        class="btn"
        :class="{ 'btn--disabled': !cardNumber }"
        @click="searchSessionForPay"
      >
        Далее
      </button>
    </div>

    <div class="footer">
      <p class="footer__text">В приложении быстрее и проще:</p>
      <div class="footer__links">
        <a
          href="https://play.google.com/store/apps/details?id=com.parkpass.app"
          class="load-app__link"
          aria-label="Скачать приложение в google play"
        >
          <img src="img/app-google.png" width="100" alt="google play icon" />
        </a>
        <a
          href="https://itunes.apple.com/ru/app/parkpass/id1397876828?mt=8"
          class="load-app__link"
          aria-label="Скачать приложение в app store"
        >
          <img src="img/app-apple.png" width="86" alt="apple icon" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api";
import * as _ from "lodash";
export default {
  name: "StartPage",
  data() {
    return {
      parkings: [],
      cardNumber: "",
      selectedParking: null,
      page: 1,
      SELECT_PARKING_PAGE: 1,
      ENTER_CARD_NUMBER_PAGE: 2
    };
  },
  created() {
    const parkingId = this.$route.query.P;
    Api.getAllParking().then(res => {
      this.parkings = res.data.result;
      if (parkingId) {
        this.selectedParking =
          _.find(this.parkings, ["id", Number(parkingId)]) || null;
        if (this.selectedParking) {
          this.page = this.ENTER_CARD_NUMBER_PAGE;
        }
      }
    });
  },
  methods: {
    selectParking() {
      this.page = this.ENTER_CARD_NUMBER_PAGE;
      setTimeout(() => {
        this.$refs.input.focus();
      });
    },
    searchSessionForPay() {
      window.location.href = `${window.location.protocol}//${window.location.host}/#/?P=${this.selectedParking.id}&C=${this.cardNumber}`;
      location.reload();
    }
  }
};
</script>

<style scoped lang="scss">
.body {
  padding-top: 80px;
  padding-bottom: 20px;
  //min-height: 100vh;
  min-height: calc(100vh - 70px);
}

.logo-icon {
  margin-bottom: 25px;
}

.animation-top {
  margin-bottom: 40px;
}

.control-wrap {
}

.select {
  position: relative;

  select {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 100%;
    height: 64px;
    text-align-last: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    font-family: "SF Pro Display";
    text-align: center;
    color: #060606;
    padding: 0 50px;
  }
}

.select__arrow {
  position: absolute;
  left: 22px;
  top: 50%;
  transform: translateY(-50%);
}

.input {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 100%;
  height: 64px;
  text-indent: 1px;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  font-family: "SF Pro Display";
  font-weight: 400;
  text-align: center;
  border: none;
  margin: 0;
  padding: 0;
}

.btn {
  background: #00d215;
  border-radius: 8px;
  width: 100%;
  height: 64px;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  font-family: "SF Pro Display";
  margin-top: 21px;
  box-shadow: none;
  border: none;
}

.btn--disabled {
  opacity: 0.25;
  pointer-events: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.footer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding-top: 50px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
}

.footer__text {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.footer__links {
  display: grid;
  width: 100%;
  gap: 9px;
  grid-auto-flow: column;

  .load-app__link {
    width: 100%;
    height: 40px;
  }
}

@media (max-width: 360px) {
  .select select {
    font-size: 18px;
  }
}
</style>
