<template>
  <div class="body searching__body searching__body--1" ref="body">
    <div class="logo-icon">
      <img src="img/pass-icon-1.png" alt="icon" />
    </div>
    <div class="body__title-wrap">
      <h2 class="body__title title" v-if="!queryParamsError">
        Ищем вашу <br />
        парковочную сессию...
      </h2>
      <h2 class="body__title title" v-else>
        Не указаны параметры <br />
        для поиска
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "Searching",
  props: ["queryParamsError"]
};
</script>

<style scoped></style>
