<template>
  <div class="session-detail-page">
    <div class="body session__body">
      <!--      <div class="session__logo-icon logo-icon">-->
      <!--        <img src="img/pass-icon-1.png" alt="icon" />-->
      <!--      </div>-->
      <div class="animation-top">
        <h2 class="body__title title title--bold">
          {{ settings.sessionInfo.parking_name }}
        </h2>
        <p class="body__desc small-text">
          {{ settings.sessionInfo.parking_address }}
        </p>
      </div>

      <span class="separator" aria-hidden="true"></span>

      <div class="body__block-wrapper animation-middle">
        <h3 class="body__subtitle subtitle">К оплате</h3>
        <div class="label-block label-block--big-text">
          <p class="label-block__big-text">
            {{ settings.sessionInfo.debt }} {{ currency }}
          </p>
        </div>
      </div>

      <div class="session-detail__wrapper animation-bottom">
        <h3 class="body__subtitle subtitle">Детали сессии</h3>
        <div class="session-detail label-block">
          <ul>
            <li>
              Время на парковке:
              <span>{{ getSessionTime(settings.sessionInfo.duration) }}</span>
            </li>
            <li>
              Въезд: <span>{{ entryDate }}</span>
            </li>
            <li>
              Номер карты:
              <span>{{ settings.sessionInfo.parking_card_id }}</span>
            </li>
            <li>
              Номер ТС:
              <span>{{
                settings.sessionDetail.plateNumber
                  ? settings.sessionDetail.plateNumber
                  : "-"
              }}</span>
            </li>
          </ul>
          <div
            class="session-detail__img"
            v-if="settings.sessionDetail.carPhoto"
          >
            <img :src="settings.sessionDetail.carPhoto" alt />
          </div>
        </div>
        <div class="session-detail__not-my-session-link label-block">
          <a
            class="label-block__link"
            @click="settings.sessionFoundError = true"
            >Это не моя сессия!</a>
        </div>
      </div>
    </div>
    <div class="sticky-bottom-area">
      <button
        class="pay-button animation-button"
        type="button"
        v-if="+settings.sessionInfo.debt"
        @click="settings.paymentStep = true"
      >
        Перейти к оплате
      </button>
      <button class="pay-button animation-button" type="button" v-else>
        Оплата не требуется!
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Helpers from "@/mixins/Helpers";
export default {
  name: "SessionDetail",
  mixins: [Helpers],
  props: ["settings"],
  computed: {
    entryDate() {
      if (this.settings.sessionInfo.entered_at) {
        return moment(this.settings.sessionInfo.entered_at).format(
          "DD.MM.YYYY В HH:mm"
        );
      }
      return "-";
    },
    currency() {
      switch (this.settings.sessionInfo.currency) {
        case "KZT":
          return "₸";
        default:
          return "₽";
      }
    }
  }
};
</script>

<style scoped></style>
