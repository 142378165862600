<template>
  <div>
    <h1 class="visually-hidden">RPS. Оплата парковки</h1>
    <transition name="page-animation" mode="out-in">
      <SessionFoundError v-if="settings.sessionFoundError" v-bind:key="3" />
      <SearchingError
        v-else-if="settings.searchingSessionError"
        v-bind:key="4"
      />
      <Payment
        v-else-if="settings.paymentStep"
        :settings="settings"
        @getSessionInfo="getSessionInfo"
        v-bind:key="5"
      />
      <SessionDetail
        v-else-if="settings.sessionDetail"
        :settings="settings"
        v-bind:key="6"
      />
      <Searching
        v-else-if="
          !settings.qrSite &&
            !settings.sessionInfo &&
            !settings.queryParamsError
        "
        :queryParamsError="settings.queryParamsError"
        v-bind:key="7"
      />
      <StartPage v-else-if="settings.queryParamsError" />
      <Searching_2 v-else-if="!settings.qrSite" v-bind:key="8" />
    </transition>
    <div class="ppw-preloader" v-if="settings.load">
      <svg
        version="1.1"
        id="L9"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enable-background="new 0 0 0 0"
        xml:space="preserve"
      >
        <path
          fill="#fff"
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  </div>
</template>
<script>
import Api from "@/api";
import Searching from "@/views/Searching";
import Searching_2 from "@/views/Searching_2";
import SearchingError from "@/views/SearchingError";
import SessionDetail from "@/views/SessionDetail";
import SessionFoundError from "@/views/SessionFoundError";
import Payment from "@/views/Payment";
import moment from "moment";
import { showAlert } from "@/utils";
import CryptoJS from "crypto-js";
import Helpers from "@/mixins/Helpers";
import StartPage from "@/views/StartPage";

export default {
  name: "Home",
  mixins: [Helpers],
  data() {
    return {
      settings: {
        queryParamsError: false,
        searchingSessionError: false,
        sessionFoundError: false,
        paymentError: false,
        paymentSuccess: false,
        sessionInfo: null,
        sessionDetail: null,
        paymentStep: false,
        leave_at: null,
        qrSite: false,
        load: false
      }
    };
  },
  created() {
    const self = this;
    this.resetLocalStorage();
    // http://localhost:8080/#/?P=1&C=2719885733
    // http://localhost:8080/#/success?Success=true&ErrorCode=0&Message=None&Details=&Amount=180000&MerchantEmail=support%40parkpass.ru&MerchantName=PARKPASS%20WEB&OrderId=6215&PaymentId=305006029&TranDate=&BackUrl=https%3A%2F%2Fparkpass.ru%2Fpayment&CompanyName=%D0%9E%D0%9E%D0%9E%20%22%D0%9F%D0%90%D0%A0%D0%9A%D0%9F%D0%90%D0%A1%D0%A1%22&EmailReq=support%40parkpass.ru&PhonesReq=9099473763
    if (!this.$route.query.P || !this.$route.query.C) {
      this.settings.queryParamsError = true;
    } else {
      if (this.$route.query.Q && this.$route.query.Q === "4815162342") {
        this.$localStorage.set("qrSiteReferrer", document.referrer);
        this.settings.qrSite = true;
        document.querySelector("body").classList.add("qr-site-theme");
      }
      if (this.$route.query.Q && this.$route.query.Q === "48151623422") {
        this.settings.qrSite = true;
      }
      Api.getSession({
        parking_id: this.$route.query.P,
        card_id: this.$route.query.C
      })
        .then(res => {
          setTimeout(
            () => {
              this.settings.sessionInfo = res.data;
              const formData = new FormData();

              let rpsHostName = new URL(res.data.source_hostname);
              rpsHostName.port = "";
              rpsHostName.protocol = "https://";
              const host = rpsHostName.host;
              rpsHostName = rpsHostName.toString();

              const DeveloperId = "fe254330-3baf-411b-aa79-e410fe7e700b";
              const Key = "880a41b76f862284f2fc520b900635dd3f7300a8";
              const time = moment();
              const HashString = `${time.unix()}${DeveloperId.toUpperCase()}${host}${Key}`;
              const md5Hash = CryptoJS.MD5(HashString);

              formData.append("cardId", res.data.parking_card_id);
              formData.append("time", time.unix());
              formData.append("enterTime", res.data.entered_at);
              formData.append("developerId", DeveloperId);
              formData.append("hash", md5Hash.toString());

              self.$localStorage.set("sessionId", self.settings.sessionInfo.id);
              self.$localStorage.set("debt", this.settings.sessionInfo.debt);
              self.$localStorage.set(
                "duration",
                self.settings.sessionInfo.duration
              );
              self.$localStorage.set(
                "parking_name",
                self.settings.sessionInfo.parking_name
              );
              // TODO вернуть
              // this.settings.sessionDetail = {};

              Api.getSessionDetail(rpsHostName, formData)
                .then(res => {
                  setTimeout(
                    () => {
                      if (res.data.status === "success") {
                        this.settings.sessionDetail = res.data;
                      } else {
                        // this.settings.searchingSessionError = true;
                        // showAlert("error", "", res.data.message);
                        this.settings.sessionDetail = {};
                      }
                    },
                    self.settings.qrSite ? 0 : 3000
                  );
                })
                .catch(() => {
                  setTimeout(
                    () => {
                      // this.settings.searchingSessionError = true;
                      // showAlert("error", "", err.response.data);
                      this.settings.sessionDetail = {};
                    },
                    self.settings.qrSite ? 0 : 3000
                  );
                })
                .finally(() => {
                  if (self.settings.qrSite) {
                    self.settings.paymentStep = true;
                  }
                });
            },
            self.settings.qrSite ? 0 : 1500
          );
        })
        .catch(err => {
          this.settings.searchingSessionError = true;
          showAlert("error", "", err.response.data.message);
        })
        .finally(() => {
          self.$localStorage.set("initialUrl", window.location.href);
          // window.history.pushState(
          //   {},
          //   document.title,
          //   window.location.pathname
          // );
        });
    }
  },
  methods: {
    async getSessionInfo(callback) {
      await Api.getSession({
        parking_id: this.$route.query.P,
        card_id: this.$route.query.C
      }).then(res => {
        this.settings.sessionInfo = res.data;
        callback();
      });
      return true;
    }
  },
  components: {
    Searching,
    Searching_2,
    SearchingError,
    SessionDetail,
    SessionFoundError,
    Payment,
    StartPage
  }
};
</script>
