<template>
  <div>
    <div class="tinkoff-preloader" v-if="load">
      <svg
        version="1.1"
        id="L9"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enable-background="new 0 0 0 0"
        xml:space="preserve"
      >
        <path
          fill="#fff"
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
    <div id="tinkoffWidgetContainer"></div>
    <form
      name="TinkoffPayForm"
      onsubmit="pay(this); return false;"
      style="display: none;"
    >
      <input
        class="tinkoffPayRow"
        type="hidden"
        name="terminalkey"
        :value="terminalKey"
      />

      <input class="tinkoffPayRow" type="hidden" name="frame" value="false" />
      <input class="tinkoffPayRow" type="hidden" name="language" value="ru" />
      <input
        class="tinkoffPayRow"
        type="text"
        placeholder="Сумма заказа"
        name="amount"
        :value="amount"
        required
      />
      <input
        class="tinkoffPayRow"
        type="text"
        placeholder="Номер заказа"
        name="order"
        :value="orderId"
      />
      <input
        class="tinkoffPayRow"
        type="text"
        placeholder="Описание заказа"
        name="description"
        value="Оплата парковочной карты"
      />
      <!--      <input-->
      <!--        class="tinkoffPayRow"-->
      <!--        type="text"-->
      <!--        placeholder="ФИО плательщика"-->
      <!--        name="name"-->
      <!--      />-->
      <input
        class="tinkoffPayRow"
        type="text"
        placeholder="E-mail"
        name="email"
        :value="email"
      />
      <input
        class="tinkoffPayRow"
        type="text"
        placeholder="receipt"
        name="receipt"
        :value="
          JSON.stringify({
            Taxation: 'usn_income',
            Items: [
              {
                Name: 'Оплата парковочной карты',
                Price: amount * 100,
                Quantity: 1.0,
                Amount: amount * 100,
                Tax: 'none'
              }
            ]
          })
        "
      />
      <!--      <input-->
      <!--        class="tinkoffPayRow"-->
      <!--        type="text"-->
      <!--        placeholder="Контактный телефон"-->
      <!--        name="phone"-->
      <!--      />-->
      <input class="tinkoffPayRow" type="submit" value="Оплатить" />
    </form>
  </div>
</template>

<script>
import Helpers from "@/mixins/Helpers";

export default {
  name: "TinkoffPayWidget",
  props: ["email", "orderId", "amount"],
  mixins: [Helpers],
  data() {
    return {
      terminalKey: "1560188286835",
      load: true
    };
  },
  created() {
    if (document.getElementById("my-datatable")) return; // was already loaded
    var scriptTag = document.createElement("script");
    scriptTag.src =
      "https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js";
    scriptTag.id = "my-datatable";
    document.getElementsByTagName("head")[0].appendChild(scriptTag);
  },
  mounted() {
    const vm = this;
    setTimeout(() => {
      const terminalkey = document.forms.TinkoffPayForm.terminalkey;
      let paymentSystems = {
        GooglePay: {
          environment: "PRODUCTION",
          merchantId: "BCR2DN6TZ7VIFI23",
          buttonColor: "white",
          buttonType: "long",
          paymentInfo: function() {
            return {
              infoEmail: vm.email,
              paymentData: document.forms.TinkoffPayForm
            };
          }
        }
      };
      if (this.iOS || this.isSafari) {
        paymentSystems = {
          ApplePay: {
            buttonOptions: {
              // color: "black"
            },
            paymentInfo: function() {
              return {
                infoEmail: vm.email,
                paymentData: document.forms.TinkoffPayForm
              };
            }
          }
        };
      }
      const widgetParameters = {
        container: "tinkoffWidgetContainer",
        terminalKey: terminalkey.value,
        paymentSystems,
        paymentInfo: function() {
          return {
            infoEmail: vm.email,
            paymentData: document.forms.TinkoffPayForm
          };
        }
      };
      // eslint-disable-next-line no-undef
      initPayments(widgetParameters);
      setTimeout(function() {
        vm.load = false;
      }, 500);
    }, 1000);
  }
};
</script>

<style scoped></style>
