import { render, staticRenderFns } from "./SessionFoundError.vue?vue&type=template&id=2b48952a&scoped=true&"
import script from "./SessionFoundError.vue?vue&type=script&lang=js&"
export * from "./SessionFoundError.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b48952a",
  null
  
)

export default component.exports