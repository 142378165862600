var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.load)?_c('div',{staticClass:"tinkoff-preloader"},[_c('svg',{attrs:{"version":"1.1","id":"L9","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 100 100","enable-background":"new 0 0 0 0","xml:space":"preserve"}},[_c('path',{attrs:{"fill":"#fff","d":"M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"}},[_c('animateTransform',{attrs:{"attributeName":"transform","attributeType":"XML","type":"rotate","dur":"1s","from":"0 50 50","to":"360 50 50","repeatCount":"indefinite"}})],1)])]):_vm._e(),_c('div',{attrs:{"id":"tinkoffWidgetContainer"}}),_c('form',{staticStyle:{"display":"none"},attrs:{"name":"TinkoffPayForm","onsubmit":"pay(this); return false;"}},[_c('input',{staticClass:"tinkoffPayRow",attrs:{"type":"hidden","name":"terminalkey"},domProps:{"value":_vm.terminalKey}}),_c('input',{staticClass:"tinkoffPayRow",attrs:{"type":"hidden","name":"frame","value":"false"}}),_c('input',{staticClass:"tinkoffPayRow",attrs:{"type":"hidden","name":"language","value":"ru"}}),_c('input',{staticClass:"tinkoffPayRow",attrs:{"type":"text","placeholder":"Сумма заказа","name":"amount","required":""},domProps:{"value":_vm.amount}}),_c('input',{staticClass:"tinkoffPayRow",attrs:{"type":"text","placeholder":"Номер заказа","name":"order"},domProps:{"value":_vm.orderId}}),_c('input',{staticClass:"tinkoffPayRow",attrs:{"type":"text","placeholder":"Описание заказа","name":"description","value":"Оплата парковочной карты"}}),_c('input',{staticClass:"tinkoffPayRow",attrs:{"type":"text","placeholder":"E-mail","name":"email"},domProps:{"value":_vm.email}}),_c('input',{staticClass:"tinkoffPayRow",attrs:{"type":"text","placeholder":"receipt","name":"receipt"},domProps:{"value":JSON.stringify({
          Taxation: 'usn_income',
          Items: [
            {
              Name: 'Оплата парковочной карты',
              Price: _vm.amount * 100,
              Quantity: 1.0,
              Amount: _vm.amount * 100,
              Tax: 'none'
            }
          ]
        })}}),_c('input',{staticClass:"tinkoffPayRow",attrs:{"type":"submit","value":"Оплатить"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }