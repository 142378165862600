<template>
  <div v-click-outside="hideList">
    <div class="pay-button" @click="show = true">
      <svg
        class="pay-button__icon"
        width="35"
        height="44"
        viewBox="0 0 35 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <use xlink:href="#sbp-icon"></use>
      </svg>
      <span class="pay-button__title">Система быстрых платежей</span>
      <span class="pay-button__desc">Среднее время оплаты 32 секунды</span>
    </div>

    <div class="list" :class="{ 'list--open': expand }" v-if="show">
      <p class="list__title">
        <template v-if="paymentProcess">
          Ожидаем завершения <br />
          оплаты в приложении банка...
        </template>
        <template v-else>
          Выберите банк
        </template>
      </p>

      <div class="list__search">
        <input
          type="text"
          placeholder="Введите название банка"
          v-model="search"
          ref="search"
        />
      </div>
      <div class="list__inner" v-if="!paymentProcess">
        <a
          class="list__list-item list-item"
          v-for="(item, index) in filteredBanks"
          :key="index"
          :title="item.bankName"
          :href="getBankLink(item.schema, item.package_name)"
          @click="openBankLink"
        >
          <img
            class="list-item__logo"
            :src="item.logoURL"
            :alt="item.bankName"
          />
          <span class="list-item__name">{{ item.bankName }}</span>
          <span class="list-item__arrow">></span>
        </a>
      </div>
      <div class="list__loader-circle" v-else></div>
      <div class="list__expand-btn" @click="expandList">
        <template v-if="paymentProcess"
          >Хочу оплатить в приложении другого банка</template
        >
        <template v-else>У меня другой банк</template>
      </div>
    </div>
    <svg aria-hidden="true" style="display: none;">
      <symbol
        id="sbp-icon"
        viewBox="0 0 35 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 9.3974L5.22831 18.7427V24.443L0.00611624 33.7699L0 9.3974Z"
          fill="#5B57A2"
        />
        <path
          d="M20.0746 15.342L24.9737 12.3393L35 12.33L20.0746 21.4734V15.342Z"
          fill="#D90751"
        />
        <path
          d="M20.0468 9.34238L20.0745 21.7152L14.834 18.4951V0L20.0472 9.34238H20.0468Z"
          fill="#FAB718"
        />
        <path
          d="M34.9999 12.33L24.9733 12.3393L20.0468 9.34238L14.834 0L34.9996 12.33H34.9999Z"
          fill="#ED6F26"
        />
        <path
          d="M20.0745 33.8217V27.8188L14.834 24.66L14.8369 43.1735L20.0745 33.8217Z"
          fill="#63B22F"
        />
        <path
          d="M24.9615 30.8463L5.22795 18.7427L0 9.3974L34.9788 30.8341L24.9611 30.8463H24.9615Z"
          fill="#1487C9"
        />
        <path
          d="M14.8373 43.1735L20.0742 33.8218L24.9611 30.8464L34.9784 30.8342L14.8373 43.1735Z"
          fill="#017F36"
        />
        <path
          d="M0.00610352 33.7699L14.8768 24.6603L9.87736 21.5928L5.22829 24.443L0.00610352 33.7699Z"
          fill="#984995"
        />
      </symbol>
    </svg>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import axios from "axios";
import ClickOutside from "vue-click-outside";
let banks = require("./banks.json");

export default {
  name: "SbpList",
  props: ["qr_payment_url"],
  data() {
    return {
      search: "",
      banks: [],
      expand: false,
      show: false,
      iOS:
        (!window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent)) ||
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
      paymentProcess: false
    };
  },
  created() {
    this.getBanks();
  },
  computed: {
    filteredBanks() {
      if (this.expand) {
        return this.banks.filter(i => {
          const re = new RegExp(this.search, "gi");
          return re.test(i.bankName);
        });
      }
      return this.banks.slice(0, 12);
    }
  },
  methods: {
    getBanks() {
      // axios.get("https://qr.nspk.ru/proxyapp/c2bmembers.json").then(res => {
      //   this.banks = res.data.dictionary;
      // });
      this.banks = banks.dictionary;
    },
    hideList() {
      this.expand = false;
      this.search = "";
      this.show = false;
      this.paymentProcess = false;
    },
    expandList() {
      this.expand = true;
      this.paymentProcess = false;
      setTimeout(() => {
        this.$refs.search.focus();
      }, 100);
    },
    getBankLink(schema, package_name) {
      return this.iOS
        ? `${this.qr_payment_url.replace("https:", schema + ":")}`
        : `${this.qr_payment_url.replace(
            "https:",
            "intent:"
          )}#Intent;scheme=${schema};package=${package_name};end;`;
    },
    openBankLink() {
      this.paymentProcess = true;
      this.expand = false;
      this.search = "";
    }
  },
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
/*list*/

.list {
  background: #f3f3f3;
  border-radius: 24px 24px 0 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 5px;
}
.list__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  text-align: center;
}
.list__loader-circle {
  width: 70px;
  height: 70px;
  border-radius: 100px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #040c44;
  //border-right: 4px solid #040c44;
  border-bottom: 4px solid #040c44;
  margin-top: 90px;
  margin-bottom: 100px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.list__search {
  width: 100%;
  margin-top: 35px;
  display: none;
}
.list__search input {
  background: #ffffff;
  border: 1px solid #cdcdcd;
  box-sizing: border-box;
  border-radius: 12px;
  width: 100%;
  height: 52px;
  padding: 23px;
}
.list__search input::placeholder {
  color: #999999;
}
.list__inner {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  justify-content: center;
}
.list__list-item {
  margin: 0 9px 24px;
}

/*list-item*/

.list-item {
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.15));
  border-radius: 8px;
  width: 64px;
  height: 64px;
  background: #fff;
  overflow: hidden;
  color: #000;
}
.list-item__name {
  margin-left: 10px;
  display: none;
}
.list-item__logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.list-item__arrow {
  margin-left: auto;
  margin-right: 10px;
  display: none;
}
.list__expand-btn {
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #818181;
  margin-top: 20px;
  cursor: pointer;
}

/*special js class*/

.list--open {
  padding: 24px 24px 18px;
  top: 68px;
  .list__search {
    display: flex;
  }
  .list__expand-btn {
    display: none;
  }
  .list__inner {
    justify-content: initial;
    flex-wrap: nowrap;
    flex-direction: column;
    width: 100%;
    overflow: auto;
  }
  .list__list-item {
    margin: 0;
    margin-bottom: 18px;
    flex-shrink: 0;
  }
  .list-item {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .list-item__name {
    display: block;
  }
  .list-item__arrow {
    display: block;
  }
  .list-item__logo {
    width: 42px;
    height: 42px;
  }
}
</style>
