<template>
  <div class="body searching__body searching__body--2">
    <transition name="page-animation">
      <div class="logo-icon">
        <img src="img/pass-icon-2.png" alt="icon" />
      </div>
    </transition>
    <div class="body__title-wrap">
      <h2 class="body__title title">Сессия найдена!</h2>
      <p class="body__desc small-text">Идёт загрузка данных...</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Searching_2"
};
</script>

<style scoped></style>
