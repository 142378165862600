import moment from "moment";

export default {
  data() {
    return {
      iOS: !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent),
      isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    };
  },
  methods: {
    resetLocalStorage() {
      this.$localStorage.remove("sessionId");
      this.$localStorage.remove("debt");
      this.$localStorage.remove("duration");
      this.$localStorage.remove("parking_name");
      this.$localStorage.remove("initialUrl");
    },
    getSessionTime(seconds) {
      if (seconds) {
        let output = "";

        const now = moment("2010-10-20");
        const expiration = moment("2010-10-20").seconds(seconds);
        const diff = expiration.diff(now);
        const diffDuration = moment.duration(diff);
        if (diffDuration.days()) {
          output += diffDuration.days() + " д. ";
        }
        if (diffDuration.hours()) {
          output += diffDuration.hours() + " ч. ";
        }
        if (diffDuration.minutes()) {
          output += diffDuration.minutes() + " мин.";
        }
        return output;
      }
      return "-";
    }
  }
};
